import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = ({ location }) => (
  <Layout location={location} lang={'en'}>
    <div id="main">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist...</p>
    </div>
  </Layout>
)

export default NotFoundPage
